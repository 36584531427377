.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-title {
  color: #000000;
  font-size: xx-large;
  font-weight: bold;
  margin: 0.2em;
}

.App-sub-title {
  color: #0e6f90;
  font-size: large;
}

/*
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  */
.floatbox .floatbutton {
  font-family: sans-serif;
  background-color: #008065;
  cursor: pointer;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #fff;
  box-shadow: 0 0 6px 0 rgba(141, 108, 90, 0.5);
  display: inline-flex;
  align-items: center;
  padding: 15px 15px;
  height: 19px;
  text-shadow: 0px 0px #fff;
  margin: 0px 2px;
  text-decoration: none;
  font-size: 14px;
}

.floatbox {
  z-index: 5;
  clear: both;
  bottom: 100px !important;
  position: fixed;
  display: block;
  right: -150px;
  animation: slideright 1s forwards;
  animation-delay: 1.2s;

  transform: rotate(270deg);
  height: 44px;
  display: inline-flex;
  align-items: center;
  bottom: calc(50% - 44px);
  min-width: 120px;
  min-height: 50px;
  transform: translateX(150px) rotate(270deg);
  animation: slideright 1s forwards;
  animation-delay: 1.2s;
}

@keyframes slideright {
  100% {
    right: -36px;
  }
  from {
    transform: translateX(150px) rotate(270deg);
  }
  to {
    transform: translateX(0) rotate(270deg);
  }
}
